<template> News - {{ id }} </template>

<script>
export default {
  data: () => ({
    id: "",
  }),
  created() {
    this.id = this.$route.params.id;
    console.log(this.id);
  },
};
</script>

<style></style>
